import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const AdminRoute = ({ component: Component, ...rest }) => {
	
	return (
		<Route
			{...rest}
			render={(props) => {
				if (localStorage.getItem('adminaccessToken')) {
					return <Component {...props}/>;
				} 
				return <Redirect to='/'/>;
			}}
		/>
	);
};

export default AdminRoute;