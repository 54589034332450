import { useState } from 'react';
const CookieDisabledPopup = () => {
    const [handleDisplay, setHandleDisplay] = useState(true);
    const handleClick = () => {
        setHandleDisplay(false);
    }
    return <div style={{zIndex: 9999, position: "fixed", top: "40%", left: "25%", backgroundColor: "#ff7777cc", display: handleDisplay?'block':'none'}}>
        <h4>WARNING!!! YOU HAVE COOKIES DISABLED, THIS SITE REQUIRES COOKIES TO FUNCTION PROPERLY. YOU WILL NOT BE ABLE TO ACTIVATE YOUR ACCOUNT WITHOUT COOKIES ENABLED.</h4>
        <button onClick={handleClick}>Acknowledge and continue anyway</button>
    </div>
};

export default CookieDisabledPopup;