import React, {lazy, Suspense} from 'react';
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch
  
}from "react-router-dom";

import ProtectedRoute from './layouts/ProtectedRoute'
import Footer from './images/footer-logo.png'
import './App.scss';
import 'antd/dist/antd.css';
import AppRoute from './layouts/AppRoute'
import LoggedInNotActivatedRoute from './layouts/LoggedInNotActivatedRoute';
import LoggedInNoBillingRoute from './layouts/LoggedInNoBillingRoute';
import NotLoggedInRoute from './layouts/NotLoggedInRoute';
import ActivatedRoute from './layouts/ActivatedRoute';
import AdminRoute from './layouts/AdminRoute';
import Loginpage from './pages/login/login'
import { LoadingOutlined } from '@ant-design/icons';
import CookieDisabledPopup from './components/notactivated/cookiedisabledpopup';
// const Loginpage = lazy(()=>import('./pages/login/login'));
const Signuppage = lazy(()=>import('./pages/signup/signup'));
const Guidancepage = lazy(()=>import('./pages/guidance/guidance'));
const BuildSearchPage = lazy(()=>import('./pages/buildsearch/buildsearch'));
const SearchCalibratePage = lazy(()=>import('./pages/searchcalibrate/searchcalibrate'));
const ChooseRef = lazy(()=>import('./pages/chooseref/chooseref'));
const ChooseRefAgain = lazy(()=>import('./pages/chooseref/chooserefagain'));
const BirdsEyeView = lazy(()=>import('./pages/birdseyeview/birdseyeview'));
const SearchReport = lazy(()=>import('./pages/searchreport/searchreport'));
const CondensedSearchReport = lazy(()=>import('./pages/searchreport/condensedsearchreport'));
const SearchReportPublic = lazy(()=>import('./pages/searchreport/searchreportpublic'));
const CondensedSearchReportPublic = lazy(()=>import('./pages/searchreport/condensedsearchreportpublic'));
const NotActivated = lazy(()=>import('./components/notactivated/notactivated'));
const BillingAdded = lazy(()=>import('./pages/billingAdded/billingAdded'));
// import Signuppage from './pages/signup/signup'
// import Guidancepage from './pages/guidance/guidance'
// import BuildSearchPage from './pages/buildsearch/buildsearch'
// import ChooseRef from './pages/chooseref/chooseref'
// import ChooseRefAgain from './pages/chooseref/chooserefagain'
// import BirdsEyeView from './pages/birdseyeview/birdseyeview'
// import SearchReport from './pages/searchreport/searchreport'
// import CondensedSearchReport from './pages/searchreport/condensedsearchreport'
// import SearchReportPublic from './pages/searchreport/searchreportpublic'
// import NotActivated from './components/notactivated/notactivated'
// import BillingAdded from './pages/billingAdded/billingAdded';
const ManagementPage = lazy(()=>import('./pages/management/management'));
const Accountpage = lazy(()=>import('./pages/myaccount/account'));
const AddPaymentpage = lazy(()=>import('./pages/addpayment/addpayment'));
const Forgetpasswordpage = lazy(()=>import('./pages/forget-password/forget-password'));
const Resetpasswordpage = lazy(()=>import('./pages/reset-password/reset-password'));
const VerifyEmail = lazy(()=>import('./pages/verifyemail/verifyemail'));
const AdminAccountpage = lazy(()=>import('./pages/admin/myaccount/account'));
const UsersListpage = lazy(()=>import('./pages/admin/myaccount/UsersListpage'));
const AdminChangePasswordPage = lazy(()=>import('./pages/admin/myaccount/change-password'));
const AdminBuildSearchPage = lazy(()=>import('./pages/admin/buildsearch/buildsearch'));
const AdminChooseRef = lazy(()=>import('./pages/admin/chooseref/chooseref'));
const AdminChooseRefAgain = lazy(()=>import('./pages/admin/chooseref/chooserefagain'));
const AdminBirdsEyeView = lazy(()=>import('./pages/admin/birdseyeview/birdseyeview'));
const AdminSearchReport = lazy(()=>import('./pages/admin/searchreport/searchreport'));
const Fallback = (<div className="loading-fallback"><LoadingOutlined style={{ fontSize: 80 }} spin /></div>);
/*
import AdminAccountpage from './pages/admin/myaccount/account'
import UsersListpage from './pages/admin/myaccount/UsersListpage'
import AdminChangePasswordPage from './pages/admin/myaccount/change-password'
import AdminBuildSearchPage from './pages/admin/buildsearch/buildsearch'
import AdminChooseRef from './pages/admin/chooseref/chooseref'
import AdminChooseRefAgain from './pages/admin/chooseref/chooserefagain'
import AdminBirdsEyeView from './pages/admin/birdseyeview/birdseyeview'
import AdminSearchReport from './pages/admin/searchreport/searchreport'
*/

function App() {
  var test = 'test';
  try {
    localStorage.setItem(test, test);
    localStorage.removeItem(test);
  } catch(e) {
    return <CookieDisabledPopup />;
  }
  return (
    <div className="App">
        <Router>
          <Switch>
              <NotLoggedInRoute exact path = "/" component = {Loginpage} />
              <NotLoggedInRoute exact path = "/sign-up" component = {()=>(<Suspense fallback={Fallback}><Signuppage /></Suspense>)} />
              <NotLoggedInRoute exact path = "/forget-password" component = {()=>(<Suspense fallback={Fallback}><Forgetpasswordpage /></Suspense>)} />
              <NotLoggedInRoute exact path = "/reset-password/:verifytoken" component = {()=>(<Suspense fallback={Fallback}><Resetpasswordpage /></Suspense>)} />
              <LoggedInNotActivatedRoute exact path = "/notactivated" component = {()=>(<Suspense fallback={Fallback}><NotActivated live={true}/></Suspense>)} />
              <LoggedInNoBillingRoute exact path = "/add-billing" component = {()=>(<Suspense fallback={(<h1>Loading...</h1>)}><AddPaymentpage /></Suspense>)} />
              <ActivatedRoute exact path = "/billingAdded" component = {()=>(<Suspense fallback={Fallback}><BillingAdded /></Suspense>)} />
              <ProtectedRoute exact path="/addpaymentmethod" component = {()=>(<Suspense fallback={Fallback}><AddPaymentpage /></Suspense>)} />
              <ProtectedRoute exact path = "/account" component = {()=>(<Suspense fallback={Fallback}><Accountpage/></Suspense>)} />
              <ProtectedRoute exact path = "/guidance" component = {()=>(<Suspense fallback={Fallback}><Guidancepage /></Suspense>)} />
              <ProtectedRoute exact path = "/search" component = {()=>(<Suspense fallback={Fallback}><BuildSearchPage /></Suspense>)} /> 
              <ProtectedRoute exact path = "/search-calibrate/:search_id" component = {()=>(<Suspense fallback={Fallback}><SearchCalibratePage /></Suspense>)} />
              <ProtectedRoute exact path = "/search-reference/:search_id" component = {()=>(<Suspense fallback={Fallback}><ChooseRef /></Suspense>)} /> 
              <ProtectedRoute exact path = "/search-reference-again/:search_id" component = {()=>(<Suspense fallback={Fallback}><ChooseRefAgain /></Suspense>)} /> 
              <ProtectedRoute exact path = "/eye-view/:search_id" component = {()=>(<Suspense fallback={Fallback}><BirdsEyeView /></Suspense>)} />
              <ProtectedRoute exact path = "/search-report/:search_id" component = {()=>(<Suspense fallback={Fallback}><SearchReport /></Suspense>)} />
              <ProtectedRoute exact path = "/condensed-search-report/:search_id" component = {()=>(<Suspense fallback={Fallback}><CondensedSearchReport /></Suspense>)} />
              <AppRoute exact path = "/share-search-report/:search_id" component = {()=>(<Suspense fallback={Fallback}><SearchReportPublic /></Suspense>)} />
              <AppRoute exact path = "/share-condensed-search-report/:search_id" component = {()=>(<Suspense fallback={Fallback}><CondensedSearchReportPublic /></Suspense>)} />
              <AppRoute exact path = "/auth/email/verify/:verifytoken" component = {()=>{return (<Suspense fallback={Fallback}><VerifyEmail /></Suspense>)}} />
              <AdminRoute exact path = "/admin" component = {()=>{return (<Suspense fallback={(<h1>Loading...</h1>)}><AdminAccountpage /></Suspense>)}} />
              <Route path = "/management" component = {()=>(<Suspense fallback={Fallback}><ManagementPage /></Suspense>)} />
              <Route path = "/" component = {()=>{return (<Redirect to="/" />)}} />
              {/*<AdminRoute exact path = "/admin-search" component = {AdminBuildSearchPage} /> 
              <AdminRoute exact path = "/admin-users" component = {UsersListpage} /> 
              <AdminRoute exact path = "/admin-change-password" component = {AdminChangePasswordPage} />
              <AdminRoute exact path = "/admin-search-reference/:id" component = {AdminChooseRef} /> 
              <AdminRoute exact path = "/admin-search-reference-again/:id" component = {AdminChooseRefAgain} /> 
              <AdminRoute exact path = "/admin-eye-view/:id" component = {AdminBirdsEyeView} />
  <AdminRoute exact path = "/admin-search-report/:id" component = {AdminSearchReport} />*/}
              
          </Switch>
        </Router>
        <footer>
          <a target="_blank" href="https://www.visualizeip.com/"><img src={Footer} alt="powered by" /></a>
        </footer>
    </div>
  );
}

export default App;
