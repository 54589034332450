import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const NotLoggedInRoute = ({ component: Component, ...rest }) => {
	
	return (
		<Route
			{...rest}
			render={(props) => {
				if (localStorage.getItem('accessToken')) {
                    return <Redirect to='/account'/>
				} 
				return <Component {...props}/>;
			}}
		/>
	);
};

export default NotLoggedInRoute;