import React, {useEffect} from 'react';

function Demoerror({setIsVisible, className}) {
    useEffect(()=>{
        setTimeout(()=>{
            setIsVisible(1);
        }, 5000);
    },[]);
  return <div className={className}>
      <p>Please enter an email.</p>
  </div>;
}

export default Demoerror;
