import React, {useEffect} from 'react';

function Thankyou({setIsVisible, className}) {
    useEffect(()=>{
        setTimeout(()=>{
            setIsVisible(1);
        }, 5000);
    },[]);
  return <div className={className}>
      <p>Thank you for choosing Visualize IP</p>
      <p>You should receive a reply to your email within 24 hours.</p>
  </div>;
}

export default Thankyou;
