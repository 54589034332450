//let ip="http://visualizeip-env.eba-yhn8mpqw.us-east-2.elasticbeanstalk.com/"

export let ip = process.env.REACT_APP_API||"https://d3a150nnnn9mb4.cloudfront.net/";

// let ip="http://192.168.0.2:8000/"    
// register
export const sign_up = ip+"api/auth/register/"
export const send_email_verification_api=ip+"api/auth/email/verify/"
export const verify_email_api=ip+"api/auth/email/verify/"
export const resend_email = ip+"api/auth/email/resend/"
export const get_status=ip+"api/auth/getstatus/"
export const post_status_add_billing=ip+"api/auth/getstatus/addbilling/"
export const forget_password_api=ip+"api/auth/password/forget/"
export const reset_password_verify_api=ip+"api/auth/password/forget/varify/"
export const reset_password_api=ip+"api/auth/password/forget/reset/"
export const api=ip.substr(0,ip.length-1);
export const get_management_accounts=ip+"api/auth/management/accounts/";
export const get_management=ip+"api/auth/management/getdemos/";
export const create_demo=ip+"api/auth/management/createdemo/";
export const delete_demo=ip+"api/auth/management/deletedemo/";
export const enable_demo=ip+"api/auth/management/enabledemo/";
export const disable_demo=ip+"api/auth/management/disabledemo/";
export const set_account_note=ip+"api/auth/management/setaccountnote/";
export const socket_url=api;//"http://localhost:8001";//api+":8001/";

// login 
export const login= ip+"api/auth/login/"

// list of all countrires
export const get_all_countries = ip+"api/countries";

// list of all searches
export const get_all_searches = ip+"api/searches/";

export const validate_keywords = ip+"api/validate_keywords/";

// get all users
export const get_all_users=ip+"api/users/"

// get all billing
export const get_billing =ip+"api/billing/plans/"

// get result of search
export const search_result=ip+"api/search/results/?bill"

// steps 3 search report
export const step_3=ip+"api/search/select/tiers/"

// tag search item
export const add_tagged = `${ip}api/auth/search/reporttag/add/`;//${ip}api/search/reporttag/add/?product_id=${id}&tier=${tier}&search_id=${searchId}`;
export const remove_tagged = `${ip}api/auth/search/reporttag/remove/`;

// subscriptions
export const sub_account = `${ip}api/auth/management/subscribe_account/`;
export const unsub_account = `${ip}api/auth/management/unsubscribe_account/`;
export const request_sub = `${ip}api/auth/subscriptions/request/`;
export const cancel_sub_request = `${ip}api/auth/subscriptions/cancel_request/`;
export const cancel_sub = `${ip}api/auth/subscriptions/cancel/`;

// generate report
export const get_step3_details=ip+"api/search/report/?search_id="

export const get_condensedsearch_details=ip+"api/condensedsearch/report/?search_id="

//get help
export const get_support=ip+"api/auth/support/sendmsg/"

//download report 
export const download_report=ip+"api/search/report/download/spreadsheet/?search_id="
export const download_condensedreport=ip+"api/condensedsearch/report/download/spreadsheet/?search_id="
// create bill
// export const create_bill=ip+"api/"

//first call
export const first_call_status=ip+"api/search/first_call_status/"
export const update_search_api=ip+"api/searches/"
export const first_call_api=ip+"api/search/first_call/"
export const second_call_api=ip+"api/search/second_call/"
export const third_call_ip=ip+"api/search/third_call/"

export const first_call_a_status=ip+"api/search/first_call_a_status/";
export const first_call_b_status=ip+"api/search/first_call_b_status/";
export const first_call_newa=ip+"api/search/first_call_a/";
export const first_call_newb=ip+"api/search/first_call_b/";


//Admin APIs
export const superadmin_searches_api=ip+"api/superadmin/searches/"
export const superadmin_first_call_api=ip+"api/superadmin/search/first_call/"
export const superadmin_second_call_api=ip+"api/superadmin/search/second_call/"
export const superadmin_third_call_api=ip+"api/superadmin/search/third_call/"
export const superadmin_select_tier_api=ip+"api/superadmin/search/select_tier/"
export const superadmin_report_api=ip+"api/superadmin/search/report/?search_id="
export const superadmin_download_api=ip+"api/superadmin/search/report/download/spreadsheet?search_id="
export const superadmin_users_api=ip+"api/superadmin/users/?paginate=false"
export const superadmin_reset_password=ip+"api/auth/password/reset/"