import React, {useState} from 'react';
import {MessageTwoTone, CloseSquareTwoTone, MinusSquareTwoTone} from '@ant-design/icons';
import { Form, Input, Checkbox, message } from 'antd';
import { animated, useTransition} from 'react-spring';
import "./supportpopup.scss";
import { get_support } from '../../Api/api';
import axios from 'axios';
import Thankyou from './thankyou';
import Demoerror from './demoerror';

const tailLayout = {};
const layout = {};

function Supportpopup({isVisible, setIsVisible}) {
    const [transit, setTransit] = useState(false);
    const [msg, setMsg] = useState("");
    const [email, setEmail] = useState("");
    const [submitting, setSubmitting] = useState(false);
    const popupTransition = useTransition(isVisible==1, {
        from: {opacity: 0, borderRadius: '50%', bottom: 0},
        enter: {opacity: 1, borderRadius: '50%', bottom: 50},
        leave: {opacity: 0, bottom: 0, height: 0, width: 0},
    });
    const popupTransitionClass = "supportpopup"+(isVisible==1?" h":"");
    const popupMinimizerTransition = useTransition(isVisible==1, {
        from: {opacity: 0, bottom: 0},
        enter: {opacity: 1, bottom: 25},
        leave: {opacity: 0, bottom: 0, height: 0, width: 0},
    });
    const popupMinimizerTransitionClass = "supportpopupminimizer"+(isVisible==1?" h":"");
    const chatTransition = useTransition(isVisible==2, {
        from: {opacity: 1, borderRadius: '50%', width: 80, height: 80},
        enter: {opacity: 1, borderRadius: '5px', width: 310, height: 500},
        leave: {opacity: 0, borderRadius: '50%', width: 0, height: 0},
    });
    const chatTransitionClass = "supportbox"+(isVisible==2?" h":"");
    const popupMiniTransition = useTransition(isVisible==3, {
        from: {opacity: 0, bottom: 0},
        enter: {opacity: 1, bottom: 30},
        leave: {height: 0, width: 0, opacity: 0, bottom: 0},
    });
    const popupMiniTransitionClass = "supportpopupmini"+(transit?" h":"");
    const popupThankTransition = useTransition(isVisible==4, {
        from: {opacity: 0, bottom: 0, height: 0, width: 0},
        enter: {opacity: 1, bottom: 50, height: 200, width: 200},
        leave: {height: 0, width: 0, opacity: 0, bottom: 0},
    });
    const thankTransitionClass = "thankyoumsg";
    const popupErrorTransition = useTransition(isVisible==5, {
        from: {opacity: 0, bottom: 0, height: 0, width: 0},
        enter: {opacity: 1, bottom: 50, height: 200, width: 200},
        leave: {height: 0, width: 0, opacity: 0, bottom: 0},
    });
    const handleClose = () => {
        setIsVisible(1);
        setMsg("");
        setEmail("");
    }
    const handleOpen = () => {
        setIsVisible(2);
    }
    const handleShow = () => {
        setIsVisible(2);
        
    }
    const handleMinimize = () => {
        setIsVisible(3);
    }
    const sendMsg = () => {
        setIsVisible(4);
        axios.post(get_support, {msg: msg, email: email}, { headers: { Authorization: `Token ${localStorage.getItem("accessToken")}` } }).then(res=>{
            setMsg("");
            setEmail("");
        }).catch(err=>{
            setIsVisible(5);
        });
    }
    return (
        <>
            {popupTransition((style, item) => item ? (<animated.div style={style} className={popupTransitionClass} onClick={handleOpen}>
                <MessageTwoTone className={"helpIcon"}/>
            </animated.div>): '')}
            {popupMinimizerTransition((style, item) => item ? (<animated.div style={style} className={popupMinimizerTransitionClass} onClick={handleMinimize}>
                <span className="hidetext">Hide Chat</span>
            </animated.div>):'')}
            {popupMiniTransition((style, item) => item ? (<animated.div style={style} className={popupMiniTransitionClass} onClick={handleShow}>
                <span>Open Chat</span>
            </animated.div>): '')}
            {chatTransition((style, item) => item ? (<animated.div style={style} className={chatTransitionClass} >
                <div className="chat-controls">
                    <CloseSquareTwoTone onClick={handleClose}/>
                    <MinusSquareTwoTone onClick={handleMinimize}/>
                </div>
                <div className="chat-content">
                    <span className="server-msg">Please type your message below.</span>
                    <span className="server-msg">Make sure to include as much relevant information as possible</span>
                    <span className="server-msg">After you send your message we will be contacting you via email about your inquiry.</span>
                </div>
                <div className="chat-input">
                <Form 
                autoFocus={false}
                autocomplete="off"
                    name="basic"
                    {...layout}
                >
                    <div className="login-form" style={{padding: '0'}}>
                        <Form.Item
                            name={[ 'email']} >
                                <input value={email} className="email-input" type="text" placeholder="Email * Optional" onChange={(e)=>{setEmail(e.target.value)}} />
                            <Input value={msg} placeholder="Message" autoFocus={false} onChange={(e)=>{setMsg(e.target.value)}}/>
                            <button className={"send-msg-btn"} onClick={sendMsg}>Send</button>
                        </Form.Item>
                    </div>
                </Form>
                </div>
            </animated.div>): '')}
            {popupThankTransition((style, item) => item ? (<animated.div style={style} className={thankTransitionClass}>
                <Thankyou setIsVisible={setIsVisible} className={""}/>
            </animated.div>): '')}
            {popupErrorTransition((style, item) => item ? (<animated.div style={style} className={thankTransitionClass}>
                <Demoerror setIsVisible={setIsVisible} className={""}/>
            </animated.div>): '')}
        </>
    );
}

export default Supportpopup;
