import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const LoggedInNotActivatedRoute = ({ component: Component, ...rest }) => {
	
	return (
		<Route
			{...rest}
			render={(props) => {
				if (localStorage.getItem('accessToken')) {
                    const user = JSON.parse(localStorage.getItem('user_info'));
					if(user && user.isEmailVerified) return <Redirect to='/add-billing' />
					return <Component {...props}/>;
				} 
				return <Redirect to='/'/>;
			}}
		/>
	);
};

export default LoggedInNotActivatedRoute;