import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const LoggedInNoBillingRoute = ({ component: Component, ...rest }) => {
	
	return (
		<Route
			{...rest}
			render={(props) => {
				if (localStorage.getItem('accessToken')) {
                    const user = JSON.parse(localStorage.getItem('user_info'))
                    if(user && user.isEmailVerified) {
                        if(!user.isBillingActivated)
                            return <Component {...props}/>;
                        return <Redirect to='/account' />
                    }
                    return <Redirect to='/notactivated' />
				} 
				return <Redirect to='/'/>;
			}}
		/>
	);
};

export default LoggedInNoBillingRoute;