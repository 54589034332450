import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const ActivatedRoute = ({ component: Component, ...rest }) => {
	
	return (
		<Route
			{...rest}
			render={(props) => {
				if (localStorage.getItem('accessToken')) {
					const userInfo = JSON.parse(localStorage.getItem('user_info'));
                    if(userInfo&&userInfo.isEmailVerified)
					    return <Component {...props}/>;
                    else return <Redirect to="/notactivated"/>
				} 
				return <Redirect to='/'/>;
			}}
		/>
	);
};

export default ActivatedRoute;