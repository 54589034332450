import React, { useState, useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import Supportpopup from '../components/supportpopup/supportpopup';

const ProtectedRoute = ({ component: Component, ...rest }) => {
	const [isVisible, setIsVisible] = useState(0);
	useEffect(()=>{
		setTimeout(()=>{
			setIsVisible(1);
		},10000);
	},[])
	return (
		<Route
			{...rest}
			render={(props) => {
				if (localStorage.getItem('accessToken')) {
					let userInfo = JSON.parse(localStorage.getItem('user_info'));
					if(userInfo&&userInfo.isBillingActivated){
						let today = new Date();
						console.log(userInfo);
						if(userInfo.exp_year>today.getFullYear() || (userInfo.exp_year==today.getFullYear() && userInfo.exp_month>today.getMonth()))
							return <><Component {...props}/><Supportpopup isVisible={isVisible} setIsVisible={setIsVisible}/></>;
						else {
							return <Redirect to='/billing-expired' />;
						}
					} else {
						return <Redirect to='/add-billing'/>;
					}
				} 
				return <Redirect to='/'/>;
			}}
		/>
	);
};

export default ProtectedRoute;