import React, { Component } from 'react';
import axios from 'axios';
import { Form, Input, Checkbox, message } from 'antd';

import Logo from '../../images/logo.png'
import { Link } from 'react-router-dom'
import { login } from '../../Api/api';

const tailLayout = {};
const layout = {};

class Loginpage extends Component {
    constructor() {
        super();
        this.state = {
           submitting:false,
           email: '',
           password: '',
        };
    }
    
    render() {
        const onFinish = () => {
            console.log("finishing");
            
            this.setState({...this.state, submitting:true})
            const values = {email: this.state.email, password: this.state.password};
            axios.post(login, values )
            .then((response) => {
                const { data } = response
                // console.log(data);
                if(data.is_superuser) {
                    localStorage.setItem('adminaccessToken', data.token);
                    localStorage.setItem('user_info', JSON.stringify({isBillingActivated: data.isBillingActivated, isEmailVerified: data.isEmailVerified, exp_month: data.exp_month, exp_year: data.exp_year, card_number: data.card_number, is_subscribed: data.is_subscribed}))
                    this.props.history.push("/admin")
                }else {
                    localStorage.setItem('accessToken', data.token);
                    localStorage.setItem('user_info', JSON.stringify({isBillingActivated: data.isBillingActivated,isEmailVerified: data.isEmailVerified,exp_month: data.exp_month,exp_year: data.exp_year,card_number: data.card_number, is_subscribed: data.is_subscribed}))
                    this.props.history.push("/account")
                }
                
            })
            .catch((error) => {
                console.log(error);
                if(error.response) {
                   const { data } = error.response
                    message.error(data.detail)
                }
                this.setState({submitting:false})
            });
        };
        return(
            <div className="login-min pt-lg-5">
                <img className="img-fluid" style={{maxWidth:"330px", margin:"40px auto", display:"block"}} src={Logo}  alt="#"/>
                <Form 
                autoFocus={false}
                autocomplete="off"
                    name="basic"
                    {...layout}
                >
                    <div className="login-form">
                        <h6>log into your account</h6>
                        <Form.Item
                            name={[ 'email']} >
                            <Input placeholder="Username / Email" autoFocus={false} onChange={(e)=>{this.setState({...this.state, email: e.target.value})}}/>
                        </Form.Item>
                        <Form.Item
                            name="password"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your password!',
                                },
                            ]}
                        >
                            <Input.Password placeholder="Password" autoFocus={false} onChange={(e)=>{this.setState({...this.state, password: e.target.value})}}/>
                        </Form.Item>
                        <Form.Item className="checkbox-custom" name="remember" valuePropName="">
                            <Checkbox>Remember me</Checkbox>
                        </Form.Item>
                    </div>
                    <div className="button-m">
                        <Form.Item {...tailLayout}>
                            {this.state.submitting && <button type="submit" disabled className="submit-btn" >Submitting...</button>}
                            {!this.state.submitting && <button type="submit" className="submit-btn" onClick={onFinish}>login</button>}
                        </Form.Item>
                    </div>
                </Form>
                <em>
                    Not registered?  
                     <Link to="/sign-up">
                    Create an Account</Link>
                    <br />
                    Forget password?  
                     <Link to="/forget-password">
                    Click here</Link>
                </em>
            </div>
        )
    }
}
export default Loginpage;